'use client'

import { Hero as HeroUI } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { useSplitStore } from '@chaseweb/utils/split'
import { useEffect, useRef } from 'react'

import { DownloadLink } from '@/components'
import type { ProductPageData } from '@/data/aem/main-pages/products'
import { useHeaderCTAStore } from '@/lib/stores'

export const HeroCta = ({
  data,
  isHomepage,
}: {
  data: ProductPageData['mainContent']
  isHomepage: boolean
}) => {
  const client = useSplitStore((state) => state.clients.user)
  const treatmentCtaText = client?.getTreatment('homepage_hero_cta_text')
  const treatmentCtaSize = client?.getTreatment('homepage_hero_cta_size')

  const ctaRef = useRef<HTMLDivElement>(null)
  const { setCtaRef, setAlwaysVisible } = useHeaderCTAStore()

  useEffect(() => {
    if (client) {
      if (ctaRef.current) {
        setCtaRef(ctaRef)
      } else {
        setAlwaysVisible(true)
      }
    }
    return () => setCtaRef(null)
  }, [setCtaRef, client, setAlwaysVisible])

  const ctaLabel =
    treatmentCtaText === 'on' && isHomepage
      ? 'Open a Chase account'
      : 'Get the app'

  const isCtaBigSize = treatmentCtaSize === 'on' && isHomepage

  return data.showCta ? (
    <div ref={ctaRef} className="tw-min-h-[75px]">
      {client && (
        <HeroUI.Cta
          variant={data.variant}
          asChild
          noExternalIcon
          className={cn({
            'tw-px-8 tw-py-4': isCtaBigSize,
          })}
        >
          <DownloadLink sectionLabel="hero">{ctaLabel}</DownloadLink>
        </HeroUI.Cta>
      )}
    </div>
  ) : null
}
